.container {
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logout__btn {
    background: none;
    color: #1890ff;
    border: none;
    padding: 0;
    cursor: pointer;
}
