.footer {
    display: block;
    text-align: left;
}

.change-default-price-link {
    display: inline;
    padding: 0;
    font-style: italic;
    border: none;
    background-color: transparent;
    color: #1890ff;
    cursor: pointer;
}
